@font-face {
  font-family: 'BM-Biotif';
  src: url('./fonts/BM-Biotif-Bold.eot'), url('./fonts//BM-Biotif-Bold.woff2') format('woff2'),
    url('./fonts/BM-Biotif-Bold.woff') format('woff'),
    url('./fonts/BM-Biotif-Bold.ttf') format('truetype'),
    url('./fonts/BM-Biotif-Bold.svg') format('svg');
  font-weight: bold;
  font-style: normal;
}
* {
  margin: 0;
  padding: 0;
}
html,
body {
  margin: 0;
  padding: 0;
  min-height: 100%;
  width: 100%;
  color: #515151;
  font-family: 'Roboto', sans-serif;
  background: #f4f1f4;
}
.app {
  box-sizing: border-box;
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  left: -1px;
  header {
    display: flex;
    align-items: center;
    .container {
      margin: 0 0 0 16px;
    }
  }
  @media (min-width: 768px) {
    header {
      .container {
        margin: 0 0 0 32px;
      }
    }
  }
  @media (min-width: 1160px) {
    header {
      .container {
        margin: 0 0 0 140px;
      }
    }
  }
  .links {
    a {
      text-transform: uppercase;
    }
  }
}